import { BaseStore, Store, Field } from '@zento/lib/stores/BaseStore';
import rootStore from '@vue-storefront/core/store';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import type ICategoryState from '@vue-storefront/core/modules/catalog/types/CategoryState';
import { ExtractArrayType } from '@zento/lib/util/types';

import { fetchNavigation, FetchNavigationQuery } from './operations.graphql';
import { TopNavigationData, FooterData } from './types';

const CMS_PAGE_PREFIX = '/i/';
const ACCOUNT_PAGE_PREFIX = '/';

export type NavigationQuery = {
  navigation?: Array<ExtractArrayType<FetchNavigationQuery['navigation']>>;
};

@Store
export class NavigationStore extends BaseStore {
  /**
   * Top navigation data type
   */
  @Field
  private topNavigationData: TopNavigationData;

  /**
   * Determines all navigation data type
   */
  @Field
  private allNavigationData: TopNavigationData;

  /**
   * Feature products navigation data type
   */
  @Field
  private featureProducts: any[];

  /**
   * Secondary navigation data type
   */
  @Field
  private secondaryNavigationData: FooterData[];

  /**
   * Footer navigation data type
   */
  @Field
  private footerData: FooterData[];

  private sortInDepth(children: Array<TopNavigationData & { sortOrder?: number; __sorted?: boolean }>) {
    return children.sort((a, b) => {
      const aPos = a.sortOrder || a.position || 0;
      const bPos = b.sortOrder || b.position || 0;

      if ('children_data' in a && !a.__sorted) {
        a.children_data = this.sortInDepth(a.children_data);
        a.__sorted = true;
      }

      if ('children_data' in b && !b.__sorted) {
        b.children_data = this.sortInDepth(b.children_data);
        b.__sorted = true;
      }

      return aPos - bPos;
    });
  }

  private filterInDepth(children: TopNavigationData[]) {
    return children.filter((c) => {
      if (c.include_in_menu) {
        c.children_data = this.filterInDepth(c.children_data);

        return true;
      }

      return false;
    });
  }

  private unfoldCategoryTree(categories: TopNavigationData[], acc = {}): Record<number, TopNavigationData> {
    return categories.reduce((ret, c) => {
      return {
        ...ret,
        [c.id]: c,
        ...this.unfoldCategoryTree(c.children_data || [], acc),
      };
    }, acc);
  }

  private ensureNoCollision(
    items: Array<TopNavigationData & { __originalId?: string | number }>,
    prefix: Array<string | number> = [0],
  ): TopNavigationData[] {
    return items.map((c, i) => {
      if ('id' in c) {
        // Always work on a copy
        c = { ...c };

        if (!('__originalId' in c)) {
          c.__originalId = c.id;
        }

        c.id = [
          ...prefix,
          i,
          ...(c.kind === 'group'
            ? ['group'] // Use a prefix to ensure group and categories id's won't collide
            : []),
          c.__originalId,
        ].join('-');

        // Remove aggregation artifacts and create in place shallow copies for all children
        c.children_data = c.children_data
          .filter((cc) => cc.parent_id === c.__originalId || cc.parent_id === c.id)
          .map((cc) => ({ ...cc }));

        // Ensure all direct children will view the current item as their parent
        c.children_data.forEach((cc) => {
          cc.parent_id = c.id;
        });

        c.children_data = this.ensureNoCollision(c.children_data, [
          ...prefix,
          i,
          ...(c.kind === 'group'
            ? ['group'] // Use a prefix to ensure group and categories id's won't collide
            : []),
        ]);
      }

      return c;
    });
  }

  private enforceCategory(items: TopNavigationData[] = []) {
    items.forEach((c) => {
      c.kind = 'category';

      this.enforceCategory(c.children_data);
    });
  }

  /**
   * Fetch current top navigation data
   */
  public async fetchTopNavigationData(storeId?: number) {
    let topNavigation: NavigationQuery = { navigation: [] };

    if (!storeId) {
      storeId = currentStoreView().storeId;
    }

    try {
      topNavigation = await this.dataSources.graphQl.queue({
        ...fetchNavigation,
        params: { storeId },
      });
    } catch (e) {
      console.error('Navigation Store (fetchTopNavigationData): ', e);
    }

    let rootCategoryName = '';
    let items = topNavigation.navigation;
    let cache = this.unfoldCategoryTree((rootStore.state.category as ICategoryState).list);

    // Determine items requiring more data from elastic search
    const toResolve = [];
    items.forEach((c) => {
      if (c.target === 'category' || c.target === 'category_list') {
        if (!cache[c.category]) {
          toResolve.push(c.category);
        }
      }
    });

    if (toResolve.length) {
      // Fetch unresolved category data
      const res = await rootStore.dispatch(
        'category/list',
        {
          key: 'id',
          value: toResolve,
          includeFields: this.context.config.entities.category.includeFields,
          onlyIncludedInMenu: true,
          skipCache: true,
        },
        { root: true },
      );

      if (res && res.items) {
        cache = this.unfoldCategoryTree(res.items, cache);
      }
    }

    items = items
      .sort((a, b) => (a.sortOrder || 0) - (b.sortOrder || 0))
      .reduce((ret, c) => {
        let r: any;

        if (c.target === 'category') {
          // Clone the category without children as it's the category itself and not a tree
          const cc = cache[c.category];

          if (cc) {
            r = {
              ...cc,
              children_data: [],
              children_count: 0,
              kind: 'category',
              image: c.image ? c.image : cc.category_icon ? cc.category_icon : cc.image,
              promoted_image: cc.promoted_category_image ?? '',
              emphasis: c.emphasis,
            };
          } else {
            console.error(`Category "${c.category} - ${c.label}" is not part of your current selected store`);
          }
        } else if (c.target === 'category_list') {
          // Mark the category list as menu tree root
          const cc = cache[c.category];

          this.allNavigationData = cc;

          if (cc) {
            r = this.filterInDepth(this.sortInDepth((cc && cc.children_data) || []));

            this.enforceCategory(r); // Ensures all category children at any depth are categories

            if (!c.parentGroup) {
              // Remember the name of the root category in (last) navigation lists
              // Work around to simulate single navigation lists menu's
              rootCategoryName = cc.name;
            }
          } else {
            console.error(`Category list "${c.category} - ${c.label}" is not part of your current selected store`);
          }
        } else if (c.target === 'cms_page') {
          r = {
            to: {
              path: CMS_PAGE_PREFIX + c.url_path,
              message: c.label,
              image: c.image,
            },
            emphasis: c.emphasis,
            kind: 'cms_page',
          };
        } else if (c.target === 'external' || c.target === 'blog') {
          r = {
            to: {
              path: c.target === 'blog' && !c.url_path.startsWith('/') ? '/' + c.url_path : c.url_path,
              message: c.label,
              image: c.image,
            },
            emphasis: c.emphasis,
            kind: c.target,
          };
        } else if (/(account)/g.test(c.target)) {
          r = {
            type: 'account',
            url: ACCOUNT_PAGE_PREFIX + c.url_path,
            name: c.label,
            kind: 'account',
            image: c.image,
            emphasis: c.emphasis,
          };
        } else if (c.parentGroup === 0) {
          r = c;

          r.kind = 'group'; // Mark the group as being a group
          r.parent_id = 0; // Add parent id pointing to the root menu items
        } else {
          r = c;
        }

        if (r && c.parentGroup) {
          const g = items.find((cc) => cc.id === c.parentGroup);

          if (g) {
            const rr = Array.isArray(r) ? r : [r];

            rr.forEach((a) => (a.parent_id = c.parentGroup));
            g.children_data = [...(g.children_data || []), ...rr];
          } else {
            console.error(
              `Missing group category parent "${c.parentGroup} - ${c.label}" for category "${c.id} - ${c.label}"`,
            );
          }

          r = null;
        }

        return ret.concat(r || []);
      }, []);

    // Order items inside groups by their specified sort order
    items.forEach((i) => {
      if (i.target === 'group') {
        i.children_data = i.children_data
          .filter(Boolean)
          .sort((a, b) => ((a as any).sortOrder || 0) - ((b as any).sortOrder || 0));
      }
    });

    this.topNavigationData = {
      children_data: this.ensureNoCollision(items as TopNavigationData['children_data']),
      name: rootCategoryName,
    };

    return this.topNavigationData;
  }

  /**
   * Fetch current secondary navigation data
   */
  public async fetchSecondaryHeaderData(storeId?: number) {
    let secondaryHeaderNavigation: NavigationQuery = { navigation: [] };

    if (!storeId) {
      storeId = currentStoreView().storeId;
    }

    try {
      secondaryHeaderNavigation = await this.dataSources.graphQl.queue({
        ...fetchNavigation,
        params: { type: 'secondary_header', storeId },
      });
    } catch (e) {
      console.error('Navigation Store (fetchSecondaryHeaderData): ', e);
    }

    const navigationItems = secondaryHeaderNavigation.navigation as FooterData[];

    if (navigationItems) {
      this.secondaryNavigationData = navigationItems.sort((a, b) => a.sortOrder - b.sortOrder);

      return this.secondaryNavigationData;
    }
  }

  /**
   * Fetch current footer navigation data
   */
  public async fetchFooterData(storeId?: number) {
    let footerNavigation: NavigationQuery = { navigation: [] };

    if (!storeId) {
      storeId = currentStoreView().storeId;
    }

    try {
      footerNavigation = await this.dataSources.graphQl.queue({
        ...fetchNavigation,
        params: { type: 'footer', storeId },
      });
    } catch (e) {
      console.error('Navigation Store (fetchFooterData): ', e);
    }

    const footerItems = footerNavigation.navigation as FooterData[];

    if (footerItems) {
      const items: Map<string | number, FooterData> = new Map();

      // Ensures that group items are at start
      const sortedItems = footerItems.sort((a) => (a.target === 'group' ? -1 : 0));

      for (const item of sortedItems) {
        item.children_data = item.children_data.sort((a, b) => a.sortOrder - b.sortOrder) || [];

        if (item.target === 'group') {
          items.set(item.id, item);
        } else {
          if (items.has(item.parentGroup)) {
            const categoryChildren = items.get(item.parentGroup).children_data;

            if (!categoryChildren.includes(item)) {
              categoryChildren.push(item);
            }

            categoryChildren.filter(Boolean);
          } else {
            console.error(
              `Missing group category parent "${item.parentGroup} - ${item.label}" for category "${item.id} - ${item.label}"`,
            );
          }
        }
      }

      this.footerData = Array.from(items.values()).sort((a, b) => a.sortOrder - b.sortOrder);

      return this.footerData;
    }
  }

  /**
   * Top navigation getter
   */
  public get topNavigation() {
    return this.topNavigationData || {};
  }

  /**
   * Determines all navigation getter
   */
  public get navigationData() {
    return this.allNavigationData || {};
  }

  /**
   * Secondary navigation getter
   */
  public get secondaryNavigation() {
    return this.secondaryNavigationData || [];
  }

  /**
   * Footer navigation getter
   */
  public get footerNavigation() {
    return this.footerData || [];
  }

  /**
   * Feature product navigation getter
   */
  public get featureProductItems() {
    return this.featureProducts || [];
  }

  /**
   * Feature product navigation setter
   */
  public set featureProductItems(feature) {
    this.featureProducts = feature;
  }
}
